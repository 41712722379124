var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('base-header',{attrs:{"title":_vm.$t('app.pagos.name'),"link":"Dashboard"}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"flex-grow-1 flex-grow-0"},[_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('app.pagos.title'))+" ")]),_c('div',{staticClass:"subtitle-1 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('app.pagos.subtitle'))+" ")])]),_c('v-col',{staticClass:"d-flex align-center flex-grow-0 flex-shrink-0"},[_c('v-btn',{attrs:{"dark":""},on:{"click":_vm.openItemAdd}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" "+_vm._s(_vm.$t('app.buttons.add'))+" ")],1)],1)],1)]},proxy:true}])},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"items-per-page":10,"search":_vm.filters.selected.query},scopedSlots:_vm._u([{key:"item.costo_final",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.detalle.divisa.symbol)+" "+_vm._s(item.detalle.costo_final)+" "+_vm._s(item.detalle.divisa.code)+" ")]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.detalle.divisa.symbol)+" "+_vm._s(item.detalle.descuento)+" "+_vm._s(item.detalle.divisa.code)+" ")]}},{key:"item.costo_neto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.detalle.divisa.symbol)+" "+_vm._s(item.detalle.costo_neto)+" "+_vm._s(item.detalle.divisa.code)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openItemDetail(item)}}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1)],1)]}}])})],1)],1)],1),_c('pago-add'),_c('pago-detail')],1)}
var staticRenderFns = []

export { render, staticRenderFns }